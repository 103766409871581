// {
//   name: "Project 1",
//   type: ["react", "python"],
//   path: "/ budget-app",
//   description: "react",
//   link: "/",
//   img: "https://images.unsplash.com/photo-1617791160530-fb48acfc1658?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064",
// },
const projects = [];
export default projects;
