import React, { useState } from "react";
import ReactLoading from "react-loading";
import Accordion from "../components/Accordion";
import SectionName from "../components/SectionName";
import Navigation from "../components/Navigation";
import FormConfirmation from "../components/FormConfirmation";
import ContactForm from "../components/ContactForm";
import Link from "../components/Link";
import useScreenSize from "../hooks/useScreenSize";
import CodeSnippet from "../components/CodeSnippet";
export default function ContactMe() {
  const [isSubmitted, setSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const { isDesktop } = useScreenSize();
  let code = `const button = document.querySelector('#sendBtn');

  const message = {
    name: "${formData.name ? formData.name : ""}",
    email: "${formData.email ? formData.email : ""}",
    message: "${formData.message ? formData.message : ""}"
  }
  
  button.addEventListener('click', () => {
    form.send(message);
  })`;

  return (
    <>
      <div className="side--padding contact__me">
        {!isDesktop && <SectionName sectionName={"_contact-me"} />}
        <div className="accordion">
          <Accordion accordionLabel={"links"} isOpen={true}>
            <div className="contact__accordion">
              <Link link={"https://www.linkedin.com/in/ilkin-namazov/"} text={"linkedin"} />
              <Link link={"https://github.com/ilkinnamazov"} text={"github"} />
              <Link link={"https://tinyurl.com/bdh2p2nw"} text={"resume"} />
            </div>
          </Accordion>
        </div>
        {isLoading && (
          <>
            <div className="contact__background"></div>
            <ReactLoading type={"spokes"} className="contact__loader" />
          </>
        )}
        {isSubmitted && !isDesktop ? (
          <FormConfirmation setSubmitted={setSubmitted} />
        ) : (
          <div className="contact__wrapper">
            {!isSubmitted && <ContactForm setFormData={setFormData} setSubmitted={setSubmitted} setLoading={setLoading} />}
            {isSubmitted && isDesktop && <FormConfirmation />}
            {isDesktop && (
              <div className="contact__snippet--wrapper">
                <CodeSnippet customClass="contact__snippet" code={code} />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
