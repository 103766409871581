import { useState, useEffect } from "react";
export default function useScreenSize() {
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight, isDesktop: window.innerWidth > 769 });
  useEffect(() => {
    function handleResize(e) {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight, isDesktop: window.innerWidth > 769 });
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return screenSize;
}
