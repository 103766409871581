import variables from "../styles/abstracts/_variables.scss";
export const a11y_code = {
  'code[class*="language-"]': {
    color: variables.accentOrange,
    background: "none",
    fontFamily: variables.fontStyle,
    textAlign: "left",
    whiteSpace: "pre",
    wordSpacing: "normal",
    wordBreak: "normal",
    wordWrap: "normal",
    lineHeight: "1.5",
    MozTabSize: "4",
    OTabSize: "4",
    tabSize: "4",
    WebkitHyphens: "none",
    MozHyphens: "none",
    msHyphens: "none",
    hyphens: "none",
  },
  'pre[class*="language-"]': {
    color: "#f8f8f2",
    background: variables.backgroundColor,
    fontFamily: variables.fontStyle,
    textAlign: "left",
    whiteSpace: "pre",
    wordSpacing: "normal",
    wordBreak: "normal",
    wordWrap: "normal",
    lineHeight: "1.5",
    MozTabSize: "4",
    OTabSize: "4",
    tabSize: "4",
    WebkitHyphens: "none",
    MozHyphens: "none",
    msHyphens: "none",
    hyphens: "none",
    padding: "1em",
    margin: "0.5em 0",
    overflow: "auto",
    borderRadius: "20px",
    border: variables.borderColor,
  },
  ':not(pre) > code[class*="language-"]': {
    background: variables.backgroundColor,
    padding: "0.1em",
    borderRadius: "0.3em",
    whiteSpace: "normal",
  },
  comment: {
    color: "#859ab0",
  },
  prolog: {
    color: "#d4d0ab",
  },
  doctype: {
    color: "#d4d0ab",
  },
  cdata: {
    color: "#d4d0ab",
  },
  punctuation: {
    color: "#fefefe",
  },
  property: {
    color: "#ffa07a",
  },
  tag: {
    color: "#ffa07a",
  },
  constant: {
    color: "#ffa07a",
  },
  symbol: {
    color: "#ffa07a",
  },
  deleted: {
    color: "#ffa07a",
  },
  boolean: {
    color: variables.gradientGreen,
  },
  number: {
    color: "#00e0e0",
  },
  selector: {
    color: "#abe338",
  },
  "attr-name": {
    color: "#abe338",
  },
  string: {
    color: "#859ab0",
  },
  char: {
    color: "#abe338",
  },
  builtin: {
    color: "#abe338",
  },
  inserted: {
    color: "#abe338",
  },
  operator: {
    color: "#00e0e0",
  },
  entity: {
    color: "#00e0e0",
    cursor: "help",
  },
  url: {
    color: "#00e0e0",
  },
  ".language-css .token.string": {
    color: "#00e0e0",
  },
  ".style .token.string": {
    color: "#00e0e0",
  },
  variable: {
    color: "#00e0e0",
  },
  atrule: {
    color: "#ffd700",
  },
  "attr-value": {
    color: "#ffd700",
  },
  function: {
    color: variables.peachColor,
  },
  keyword: {
    color: variables.accentPink,
  },
  regex: {
    color: "#ffd700",
  },
  important: {
    color: "#ffd700",
    fontWeight: "bold",
  },
  bold: {
    fontWeight: "bold",
  },
  italic: {
    fontStyle: "italic",
  },
};
export const a11y_comment = {
  'code[class*="language-"]': {
    color: variables.accentOrange,
    background: "none",
    fontFamily: variables.fontStyle,
    textAlign: "left",
    whiteSpace: "pre",
    wordSpacing: "normal",
    wordBreak: "normal",
    wordWrap: "normal",
    lineHeight: "1.5",
    MozTabSize: "4",
    OTabSize: "4",
    tabSize: "4",
    WebkitHyphens: "none",
    MozHyphens: "none",
    msHyphens: "none",
    hyphens: "none",
  },
  'pre[class*="language-"]': {
    color: "#f8f8f2",
    background: variables.backgroundColor,
    fontFamily: variables.fontStyle,
    textAlign: "left",
    whiteSpace: "pre",
    wordSpacing: "normal",
    wordBreak: "normal",
    wordWrap: "normal",
    lineHeight: "1.5",
    MozTabSize: "4",
    OTabSize: "4",
    tabSize: "4",
    WebkitHyphens: "none",
    MozHyphens: "none",
    msHyphens: "none",
    hyphens: "none",
    padding: "1em",
    margin: "0.5em 0",
    overflow: "auto",
    borderRadius: "20px",
    border: variables.borderColor,
  },
  ':not(pre) > code[class*="language-"]': {
    background: variables.backgroundColor,
    padding: "0.1em",
    borderRadius: "0.3em",
    whiteSpace: "normal",
  },
  comment: {
    color: "#859ab0",
  },
  prolog: {
    color: "#d4d0ab",
  },
  doctype: {
    color: "#d4d0ab",
  },
  cdata: {
    color: "#d4d0ab",
  },
  punctuation: {
    color: "#fefefe",
  },
  property: {
    color: "#ffa07a",
  },
  tag: {
    color: "#ffa07a",
  },
  constant: {
    color: "#ffa07a",
  },
  symbol: {
    color: "#ffa07a",
  },
  deleted: {
    color: "#ffa07a",
  },
  boolean: {
    color: variables.gradientGreen,
  },
  number: {
    color: "#00e0e0",
  },
  selector: {
    color: "#abe338",
  },
  "attr-name": {
    color: "#abe338",
  },
  string: {
    color: "#859ab0",
  },
  char: {
    color: "#abe338",
  },
  builtin: {
    color: "#abe338",
  },
  inserted: {
    color: "#abe338",
  },
  operator: {
    color: "#00e0e0",
  },
  entity: {
    color: "#00e0e0",
    cursor: "help",
  },
  url: {
    color: "#00e0e0",
  },
  ".language-css .token.string": {
    color: "#00e0e0",
  },
  ".style .token.string": {
    color: "#00e0e0",
  },
  variable: {
    color: "#00e0e0",
  },
  atrule: {
    color: "#ffd700",
  },
  "attr-value": {
    color: "#ffd700",
  },
  function: {
    color: variables.peachColor,
  },
  keyword: {
    color: variables.accentPink,
  },
  regex: {
    color: "#ffd700",
  },
  important: {
    color: "#ffd700",
    fontWeight: "bold",
  },
  bold: {
    fontWeight: "bold",
  },
  italic: {
    fontStyle: "italic",
  },
};
