import Navigation from "../components/Navigation";
import Accordion from "../components/Accordion";
import CodeSnippet from "../components/CodeSnippet";
import File from "../components/File";
import Link from "../components/Link";
import SectionName from "../components/SectionName";
import useScreenSize from "../hooks/useScreenSize";
export default function AboutMe() {
  const { isDesktop } = useScreenSize();
  return (
    <>
      <section className="side--padding about__me">
        {!isDesktop && <SectionName sectionName={"_about-me"} />}
        <div className="accordion">
          <Accordion accordionLabel={"personal"} isOpen={true}>
            <File fileTags={["bio"]} isDefaultOpen={true}>
              <>bio.txt</>
            </File>
          </Accordion>
          <Accordion accordionLabel={"professional"}>
            <div className="folders">
              <Accordion iconColor={"peach"} accordionLabel={"education"} folder={true}>
                <File fileTags={["seneca"]}>
                  <>seneca.txt</>
                </File>
                <File fileTags={["udemy"]}>
                  <>udemy.txt</>
                </File>
                <File fileTags={["harbin"]}>
                  <>harbin.txt</>
                </File>
              </Accordion>
              <Accordion iconColor={"green"} accordionLabel={"experience"} folder={true}>
                <File fileTags={["cibc"]}>
                  <>cibc.txt</>
                </File>
                <File fileTags={["tedxtoronto"]}>
                  <>tedxtoronto.txt</>
                </File>
              </Accordion>
              <Accordion iconColor={"purple"} accordionLabel={"skills"} folder={true}>
                <File fileTags={["technical"]}>
                  <>technical.txt</>
                </File>
              </Accordion>
            </div>
          </Accordion>
        </div>
        <div className="content">
          <div className="file__content">
            {isDesktop && <div className="file__section"></div>}
            <div>
              {!isDesktop && <p className="file__path text--grey"></p>}
              <article className="file__text"></article>
            </div>
          </div>
          <div className="snippets__wrapper">
            <p className="snippet__showcase">&#x0002F;code snippet showcase:</p>
            <div className="snippets">
              <CodeSnippet gistID="3b8b0a2e50a7b5a47a57a80285698c05" explanation="Code snippet that I implemented while I was taking DSA course" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
