import React from "react";
export default function Link({ link, text }) {
  return (
    <div className="link">
      <a href={link} rel="noreferrer" target="_black">
        <i className="ri-external-link-line"></i>
        {text}
      </a>
    </div>
  );
}
