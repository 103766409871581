import React from "react";
export default function FormConfirmation({ setSubmitted }) {
  return (
    <div className="contact__confimation">
      <p className="contact__thankyou">Thank you! 🤘</p>
      <p className="contact__text">Your message has been accepted. You will recieve answer really soon!</p>
      <button
        onClick={() => {
          setSubmitted(false);
        }}
        className="contact__button"
      >
        send-new-message
      </button>
    </div>
  );
}
