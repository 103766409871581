import { useEffect, useMemo, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { a11y_code } from "../assets/a11y";
export default function CodeSnippet({ gistID, explanation, code, customClass }) {
  const [openDetails, setOpenDetails] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [snippetData, setSnippetData] = useState({
    username: "",
    avatar: "",
    account_url: "",
    description: "",
    date: "",
    code: "",
  });
  const getDate = (creationDate) => {
    let difference = Date.now() - new Date(creationDate);
    let days = Math.floor(difference / 1000 / 60 / (60 * 24));
    return days;
  };
  useEffect(() => {
    if (gistID) {
      axios({
        method: "get",
        url: `https://api.github.com/gists/${gistID}`,
      })
        .then((res) => {
          setSnippetData({
            username: res.data.owner.login,
            avatar: res.data.owner.avatar_url,
            date: getDate(res.data.created_at),
            account_url: res.data.owner.html_url,
            code: res.data.files["Buble Sort"].content,
          });
          setLoaded(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [gistID]);
  if (gistID) {
    return !loaded ? (
      <div className="snippet__loader">
        <BarLoader color="#fea55f" />
      </div>
    ) : (
      <>
        <div className="snippet">
          <div className="snippet_header">
            <div className="snippet__user">
              <div className="snippet__image">
                <img src={snippetData.avatar} alt={snippetData.username} />
              </div>
              <div>
                <span className="snippet__username">
                  <a href={snippetData.account_url} target="_blank" rel="noreferrer">
                    @{snippetData.username}
                  </a>
                </span>
                <p className="snippet__date">{Math.floor(snippetData.date)} days ago</p>
              </div>
            </div>
            <button
              onClick={() => {
                setOpenDetails(!openDetails);
              }}
              className="snippet__button"
            >
              details
            </button>
          </div>
          <SyntaxHighlighter style={a11y_code} className="snippet__code" language="javascript">
            {snippetData.code}
          </SyntaxHighlighter>
        </div>
        {openDetails && <p className="snippet__details">{explanation}</p>}
      </>
    );
  } else {
    return (
      <SyntaxHighlighter style={a11y_code} className={customClass} language="javascript">
        {code}
      </SyntaxHighlighter>
    );
  }
}
