import { useState, useEffect, useRef, useCallback } from "react";
import Accordion from "../components/Accordion";
import Navigation from "../components/Navigation";
import SectionName from "../components/SectionName";
import Project from "../components/Project";
import projectsList from "../assets/projects";
import Stack from "../components/Stack";
import useScreenSize from "../hooks/useScreenSize";
export default function Projects() {
  const { isDesktop } = useScreenSize();
  const [projects, setProjects] = useState([]);
  let { current: selectedStack } = useRef(["react", "vue"]);
  const filterProjects = useCallback(() => {
    let selectedProjects = [];
    selectedStack.forEach((stack) => {
      projectsList.forEach((project) => {
        if (project.type.includes(stack)) {
          selectedProjects.push(project);
        }
      });
    });
    setProjects(() => [...selectedProjects]);
  }, [selectedStack]);
  const checkboxHandler = (e) => {
    const checkboxValue = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      if (!selectedStack.includes(checkboxValue)) {
        selectedStack.push(checkboxValue);
      }
      filterProjects();
    } else {
      for (let i = 0; i < selectedStack.length; i++) {
        if (selectedStack[i] === checkboxValue) {
          selectedStack.splice(i, 1);
        }
      }
      filterProjects();
    }
  };
  useEffect(() => {
    filterProjects();
  }, [filterProjects]);
  return (
    <>
      {projects.length > 0 ? (
        <div>
          {!isDesktop && <SectionName sectionName={"_projects"} />}
          <div className="accordion">
            <Accordion accordionLabel={"projects"} isOpen={true}>
              <Stack stack={"react"} checkboxHandler={checkboxHandler} isChecked={selectedStack.includes("react")} />
              <Stack stack={"vue"} isChecked={selectedStack.includes("vue")} checkboxHandler={checkboxHandler} />
            </Accordion>
          </div>
          <div className="projects__wrapper">
            {isDesktop && <div className="projects__tab"></div>}
            <div className="projects__items">
              {projects.map(({ name, path, description, link, img, type }) => {
                return <Project name={name} path={path} description={description} link={link} img={img} type={type} key={Math.random()} />;
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="side--padding construction">
          <i className="ri-tools-fill"></i>
          <h2 className="construction__header">This section is under construction</h2>
        </div>
      )}
    </>
  );
}
