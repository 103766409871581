import { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import useScreenSize from "../hooks/useScreenSize";
export default function Navigation({ timeline }) {
  const [showNav, setShowNav] = useState(false);
  const openCloseMenu = () => {
    setShowNav(!showNav);
  };
  const { isDesktop } = useScreenSize();
  useEffect(() => {
    setShowNav(isDesktop ? true : false);
  }, [isDesktop]);

  return (
    <nav className="nav">
      <div className="nav__logo">
        <NavLink to="/">ilkin-namazov</NavLink>
      </div>
      <button className={`nav__button ${showNav ? "close--button" : ""}`} onClick={openCloseMenu}>
        <div></div>
        <div></div>
        <div></div>
      </button>
      <ul style={{ display: showNav ? "flex" : "none" }} className="nav__links">
        <li onClick={isDesktop ? null : openCloseMenu}>
          <NavLink to="/">_hello</NavLink>
        </li>
        <li onClick={isDesktop ? null : openCloseMenu}>
          <NavLink to="/about-me">_about-me</NavLink>
        </li>
        <li onClick={isDesktop ? null : openCloseMenu}>
          <NavLink to="/projects">_projects</NavLink>
        </li>
        <li onClick={isDesktop ? null : openCloseMenu}>
          <NavLink to="/contact-me">_contact-me</NavLink>
        </li>
      </ul>
    </nav>
  );
}
