import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReact, faVuejs } from "@fortawesome/free-brands-svg-icons";
export default function Stack({ stack, checkboxHandler, isChecked }) {
  let id = Math.floor(Math.random() * 100) + 1;
  let iconType = "";
  switch (stack) {
    case "react":
      iconType = <FontAwesomeIcon className="stack__icon" icon={faReact} />;
      break;
    case "vue":
      iconType = <FontAwesomeIcon className="stack__icon" icon={faVuejs} />;
      break;
    default:
      break;
  }
  return (
    <div className="stack">
      <label className="stack_label">
        <input className="stack__checkbox" type="checkbox" name={id} id={id} value={stack} onClick={checkboxHandler} defaultChecked={isChecked} />
        <span className="stack__checkmark"></span>
        {iconType}
        {stack.charAt(0).toUpperCase() + stack.slice(1)}
      </label>
    </div>
  );
}
