import React from "react";
export default function Project({ name, path, description, img, link, type }) {
  return (
    <div className="project">
      <p className="project__name">
        {name} <span className="project__path">{path}</span>
      </p>
      <div className="project__content">
        <div className="project__img" style={{ backgroundImage: `url(${img})` }}></div>
        <div className="project__info">
          <p className="project__description">{type.join(" ")}</p>
          <a className="project__link" href={link}>
            view-project
          </a>
        </div>
      </div>
    </div>
  );
}
