import { useState } from "react";

export default function Accordion({ accordionLabel, children, folder = false, iconColor, isOpen = false }) {
  const [open, setOpen] = useState(isOpen);
  let arrowDown = folder ? "ri-arrow-down-s-line" : "ri-arrow-down-s-fill";
  let arrowRight = folder ? "ri-arrow-right-s-line" : "ri-arrow-right-s-fill";
  let accordionItemClass = `accordion__item${folder ? " folder__item" : " parent__item"}`;
  let accordionFolderClass = `ri-folder-3-fill folder--${iconColor}`;
  let accordionIconClass = open ? arrowDown : arrowRight;
  let accordionButtonClass = "accordion__button";
  let accordionLabelClass = open && folder ? "accordion__label" : `${!folder ? "accordion__label" : "accordion__label folder--closed"}`;

  const accordionClickHandler = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <div className={accordionItemClass}>
      <button className={accordionButtonClass} onClick={accordionClickHandler}>
        <i className={accordionIconClass}></i>
        {folder && <i className={accordionFolderClass}></i>}
        <span className={accordionLabelClass}>{accordionLabel}</span>
      </button>
      <div style={{ display: open ? "block" : "none" }}>{children}</div>
    </div>
  );
}
