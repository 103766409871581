import { useLayoutEffect } from "react";
import filesData from "../assets/filesData";
export default function File({ children, fileTags, isDefaultOpen = false }) {
  let isClicked = isDefaultOpen ? "file clicked--file" : "file";
  const getFilePath = (e) => {
    const roolFolder = e.target.closest(".parent__item");
    const sublingButton = e.target.closest(".folder__item");
    const rootFolderLabel = "/" + roolFolder.querySelector(".accordion__label").textContent;
    const currentFileLabel = "/" + e.target.textContent;
    let sublingButtonLabel = sublingButton ? "/" + sublingButton.querySelector(".accordion__label").textContent : "";
    let path = `${rootFolderLabel}${sublingButtonLabel}${currentFileLabel}`;
    if (document.querySelector(".file__content").offsetWidth < 400 && path.replace(/\s+/g, "").length > 30) {
      path = `${rootFolderLabel}/...${currentFileLabel}`;
    }
    return path;
  };
  const onFileClick = (e) => {
    const filesItems = document.querySelectorAll(".file");
    const fileSection = document.querySelector(".file__section");
    const filePath = document.querySelector(".file__path");
    filesItems.forEach((file) => {
      file.style.borderBottom = "none";
      file.style.color = "#859ab0";
    });
    e.target.style.borderBottom = "1px solid white";
    e.target.style.color = "#fff";
    document.querySelector(".file__text").innerHTML = filesData[e.target.dataset.file];
    if (fileSection) {
      fileSection.innerHTML = getFilePath(e);
    }
    if (filePath) {
      filePath.innerHTML = getFilePath(e);
    }
  };
  useLayoutEffect(() => {
    document.querySelector(".clicked--file").click();
  }, []);

  if (!Array.isArray(children)) {
    return (
      <div className="files">
        <span className={isClicked} onClick={onFileClick} data-file={fileTags[0]}>
          <i className="ri-file-text-line"></i>
          {children}
        </span>
      </div>
    );
  }
  return (
    <article className="files">
      {children.map((child, index) => {
        return (
          <span key={Math.random()} className={isClicked} data-file={fileTags[index]} onClick={onFileClick}>
            <i className="ri-file-text-line"></i>
            {child}
          </span>
        );
      })}
    </article>
  );
}
