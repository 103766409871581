import { useEffect } from "react";
import gsap, { random } from "gsap";
import SplitType from "split-type";
import Navigation from "../components/Navigation";
import useScreenSize from "../hooks/useScreenSize";

export default function Home() {
  const { isDesktop } = useScreenSize();
  useEffect(() => {
    if (isDesktop) {
      const timeline = gsap.timeline({ repeat: -1, repeatRefresh: true, duration: 2 });
      const maxWidth = document.querySelector(".wrapper").clientWidth / 2;
      const maxHeight = document.querySelector(".wrapper").clientHeight / 2;
      timeline.to(".glow--effect", { x: `random(-1, ${maxWidth}, 20)`, y: `random(-1, ${maxHeight}, 40)` });
    }
  }, []);
  useEffect(() => {
    if (!sessionStorage.getItem("homeAnimationPlayed")) {
      const greatingText = new SplitType(".greating__text");
      const greatingHeading = new SplitType(".greating__heading");
      const timeline = gsap.timeline();

      timeline.set(".nav, .code", { opacity: "0" });
      timeline.set(".text--reveal .char", { opacity: "0" });

      timeline.to(".text--reveal .char", { stagger: 0.02, opacity: "1" });
      timeline.to(".code", { opacity: "1", duration: 1 });
      timeline.to(".nav", { opacity: "1", duration: 1 });
      sessionStorage.setItem("homeAnimationPlayed", true);
    }
  }, []);
  return (
    <>
      <div className="glow--effect"></div>
      {isDesktop && <div className="glow--effect green"></div>}
      <main className="home">
        <section className="home__content">
          <div className="greating">
            <p className="greating__text text--reveal">hey there, i'm</p>
            <h1 className="greating__heading text--reveal">Ilkin Namazov</h1>
            <p className={`greating__text text--reveal ${isDesktop ? "text--purple" : "text--green"}`}>&gt; front-end developer</p>
          </div>
          <div className="code">
            <p className="code__comment">&#x0002F;&#x0002F; find my profile on Github:</p>
            <p className="code__variable">
              <span className="text--purple">const </span>
              <span className="text--green">githubLink</span> =&nbsp;
              <a target="_blank" rel="noreferrer" href="https://github.com/ilkinnamazov" className="text--orange">
                “github.com/ilkinnamazov”
              </a>
            </p>
          </div>
        </section>
      </main>
    </>
  );
}
