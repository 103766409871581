let bio = `
I’m Ilkin Namazov, and I’m a Software Developer, currently living in Toronto, Canada.<br/>
In my free time, I study blockchain and experiment with Raspberry Pi programming.<br/>
I am both driven and self-motivated, and I am constantly experimenting with new technologies and techniques.<br/>
I am very passionate about Web Development and strive to better myself and the coding community.
`;

let seneca = `
Seneca College, Computer Programming and Analysis<br/>
Sep 2018 - Aug 2020
`;
let harbin = `Harbin Institute of Technology<br/>
Sep 2016 - June 2017`;

let udemy = `2022 - NodeJS - The Complete Guide (MVC, REST APIs, GraphQL, Deno)<br/>
2021 - Modern React with Redux<br/>
2020 - The Complete Web Development Bootcamp
2019 - React with Redux<br/>
`;

let cibc = `
CIBC, Web Developer<br/>
Sep 2020 - Present<br/>
<br/>
* Got experience in Agile environment<br/>
* Became proficient in Jira and Confluence<br/>
* Developed JavaScript-based application forms & financial tools<br/>
<br/>
CIBC, Web Developer (Co-op)<br/>
Sep 2019 - Apr 2020<br/>`;

let tedxtoronto = `TEDxToronto, Front-End Developer (Volunteer)<br/>
Jun 2020 - Mar 2021<br/>
<br/>
* Maintained the official website<br/>
* Created the UI components<br/>
* Assisted in the CMS migration process`;
let technical = `
languages:<br/>
* JavaScript<br/>
* Python<br/>
<br/>
frameworks and libraries:<br/>
* React.js & Vue.js<br/>
* Node.js & Django<br/>
<br/>
database technology:<br/>
* MySQL<br/>
* MongoDB<br/>
* Postgres<br/>
<br/>
mobile development:<br/>
* React Native
`;

const filesData = {
  bio: bio,
  seneca: seneca,
  harbin: harbin,
  udemy: udemy,
  cibc: cibc,
  tedxtoronto: tedxtoronto,
  technical: technical,
};

export default filesData;
