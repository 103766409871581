import { useState } from "react";
import axios from "axios";
export default function ContactForm({ setSubmitted, setLoading, setFormData }) {
  const [data, setData] = useState({ name: "", email: "", message: "" });
  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setData({ ...data, [name]: value });
    setFormData({ ...data, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let isEmpty = false;
    const inputs = event.target.querySelectorAll("input, textarea");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].nextElementSibling.style.display = "none";
      if (inputs[i].value.length === 0) {
        inputs[i].nextElementSibling.style.display = "block";
      }
    }
    for (let i = 0; i < inputs.length && !isEmpty; i++) {
      if (inputs[i].value.length === 0) {
        isEmpty = true;
      }
    }
    if (!isEmpty) {
      const { name, email, message } = data;
      setLoading(true);
      axios({
        method: "post",
        url: process.env.HOST,
        data: {
          name: name,
          email: email,
          message: message,
        },
      })
        .then(() => {
          setSubmitted(true);
          setLoading(false);
        })
        .catch(() => {
          console.log("Error");
        });
    }
  };
  return (
    <form className="contact__form" onSubmit={handleSubmit}>
      <div className="contact__input">
        <label htmlFor="name">_name:</label>
        <input type="text" id="name" name="name" onChange={handleInputChange} />
        <div className="contact__error">Please provide your name</div>
      </div>
      <div className="contact__input">
        <label htmlFor="email">_email:</label>
        <input type="email" id="email" name="email" onChange={handleInputChange} />
        <div className="contact__error">Please provide your email</div>
      </div>
      <div className="contact__input">
        <label htmlFor="message">_message:</label>
        <textarea id="message" name="message" onChange={handleInputChange} />
        <div className="contact__error">Please provide message</div>
      </div>
      <button className="contact__button">submit-message</button>
    </form>
  );
}
